<template>
    <div class="passwords">
        <GeneratePassword
            v-for="(item, index) in 4"
            :key="index"
            :type="index % 2 == 0 ? 'alphaNumeric' : 'word'"
            piece="4"
        />
    </div>
</template>

<script>
import GeneratePassword from "./GeneratePassword.vue";
export default {
    name: "Password",
    components: {
        GeneratePassword,
    },
};
</script>
