<template>
    <div class="passwords-wrap mb-4">
        <b-card class="password--card" :class="{ copied: passwordCopied }">
            <code class="password">{{ password }}</code>

            <b-icon
                class="copied-icon"
                icon="clipboard-plus"
                v-clipboard="() => password"
                v-clipboard:success="clipboardSuccessHandler"
                v-clipboard:error="clipboardErrorHandler"
            ></b-icon>
        </b-card>
    </div>
</template>

<script>
import { it } from "./../data/it";
import _capitalize from "lodash/capitalize";
export default {
    name: "GeneratePassword",
    props: ["piece", "type"],
    data() {
        return {
            password: "",
            passwordCopied: null,
        };
    },
    methods: {
        removeCopiedBg(copied = true) {
            this.passwordCopied = !copied;
            const copiedEl = Array.from(document.querySelectorAll(".copied"));

            copiedEl.forEach((el) => {
                el.classList.remove("copied");
            });
        },
        clipboardSuccessHandler({ value }) {
            this.removeCopiedBg(true);

            this.$bvToast.toast(
                `La password ${value} è stata copiata nella clipboard`,
                {
                    variant: "success",
                    title: "Password copiata",
                    autoHideDelay: 5000,
                }
            );

            this.passwordCopied = true;
        },
        clipboardErrorHandler({ value }) {
            this.$bvToast.toast(
                `Si è verificato un errore durante la copia della password ${value}.`,
                {
                    variant: "error",
                    title: "Password non copiata",
                    autoHideDelay: 5000,
                }
            );
        },
        word(piece) {
            this.password = this.randomWords(piece);
            return this.password;
        },
        alphaNumeric(piece) {
            const passwordBlock = [];

            let string = "";

            for (let i = 0; i < piece; i++) {
                do {
                    string = this.randomString(4, "#aA!");
                } while (!this.isAlphaNumeric(string));

                passwordBlock.push(string);
            }

            const res = (this.password = passwordBlock.join("-"));
            return res;
        },
        randomWords(piece) {
            const bag = [];

            for (let index = 0; index < piece; index++) {
                bag.push(_capitalize(this.getRandom(it)));
            }

            // Aggiunge due numeri alla fine nel caso di richiesta di numeri
            // nella password
            bag.push(this.randomString(2, "#"));

            return bag.join("-");
        },
        isAlphaNumeric(str) {
            return /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)+$/gi.test(str);
        },
        randomString(length, chars) {
            let mask = "";
            if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
            if (chars.indexOf("#") > -1) mask += "0123456789";
            if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            if (chars.indexOf("!") > -1)
                mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
            let result = "";
            for (let i = length; i > 0; --i) result += this.getRandom(mask);
            return result;
        },
        getRandom(piece) {
            return piece[Math.floor(Math.random() * piece.length)];
        },
    },
    mounted() {
        if (this.type == "word") {
            this.word(this.piece);
        } else {
            this.alphaNumeric(this.piece);
        }
    },
};
</script>

<style scoped>
.card.password--card .card-body {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 0.8rem;
}

.password {
    font-size: 0.8rem;
    margin-right: 5px;
}

.copied-icon {
    color: var(--primary);
}

.copied-icon:hover {
    color: var(--success);
    cursor: pointer;
}

.copied {
    color: #fff;
    background: rgba(40, 167, 69, 0.5);
}

.copied code {
    color: #fff;
}
</style>
